import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Application.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRegisterApplication } from "../../redux/slice/ApplicationSlice";
import { formatDate } from "../../utils/Helper";

const ApplicationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applications = useSelector(
    (state) => state?.applications?.registerdApplicaitons
  );
  const totalRecords = applications?.totalCount || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const [localPageSize, setLocalPageSize] = useState(10);
  const totalPages = Math.ceil(totalRecords / localPageSize);
  const [loading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(
        getRegisterApplication({
          pageNumber: currentPage,
          pageSize: localPageSize,
        })
      );
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, currentPage, localPageSize]);
  const handlePageChange = (newPageNumber) => {
    if (newPageNumber >= 1 && newPageNumber <= totalPages) {
      setCurrentPage(newPageNumber);
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setLocalPageSize(newPageSize);
    setCurrentPage(1);
  };
  const getStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      case "approved":
        return <i className="fh-icons approved">fh_check_circle_line</i>;
      case "pending":
        return <i className="fh-icons pending">fh_pending_process_line</i>;
      default:
        return <i className="fh-icons decline">fh_close_circle_line</i>;
    }
  };
  return (
    <div className="main-content">
      <div className="content-header">
        <h1 className="page-header">Applications</h1>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="card-content">
            <div className="content-subheader d-flex align-items-center">
              <h2 className="page-subheader me-3">
                Requested Application List
              </h2>
            </div>
            <div className="table-responsive">
              <table className="table caption-top">
                <thead>
                  <tr>
                    <th scope="col">Application Name</th>
                    <th scope="col">Requested Email</th>
                    <th scope="col">Environment</th>
                    <th scope="col">Organization Type</th>
                    <th scope="col">Requested Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center my-4">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "100%" }}
                        >
                          <div className="spinner-border" role="status"></div>
                        </div>
                      </td>
                    </tr>
                  ) : !applications?.apps.length ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    applications?.apps &&
                    applications?.apps.map((app, index) => (
                      <tr key={index}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/ApplicationView", {
                              state: { appId: app.appId },
                            })
                          }
                        >
                          <a>{app.applicationName}</a>
                        </td>
                        <td>{app.requestedEmail ? app.requestedEmail : "-"}</td>
                        <td>{app.environment}</td>
                        <td>
                          {app.organizationTypeDescription
                            ? app.organizationTypeDescription
                            : "-"}
                        </td>
                        <td>{formatDate(app.createdAt)}</td>
                        <td>
                          <div className="flex-center">
                            {getStatusIcon(app.applicationStatusDescription)}
                            {app.applicationStatusDescription}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            {applications?.apps && applications?.apps.length > 0 && (
              <div className="d-flex justify-content-center">
                <nav
                  aria-label="Page navigation"
                  className="d-flex align-items-center"
                >
                  <span className="pagination-info me-2">
                    Page{" "}
                    <strong>
                      {currentPage} of {totalPages}
                    </strong>
                  </span>
                  <ul className="pagination d-flex justify-content-end mb-0">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      >
                        <i className="fh_double_arrow_left"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <i className="fh_arrow_left_line"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <i className="fh_arrow_right_line"></i>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                      >
                        <i className="fh_double_arrow_right"></i>
                      </button>
                    </li>
                  </ul>
                </nav>
                <div>
                  <select
                    className="form-select form-control cursor-pointer custom-table-dropdown"
                    value={localPageSize}
                    onChange={(e) =>
                      handlePageSizeChange(Number(e.target.value))
                    }
                  >
                    {[10, 20, 30].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplicationList;