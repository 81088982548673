import {
    createSlice,
    createAsyncThunk,
    isPending,
    isRejected,
  } from "@reduxjs/toolkit";
import { ApplicationApi } from "../../service/Api/ApplicationsApi";
  
  const initialState = {
    isLoading: false,
    error: null as string | null,
    registerdApplicaitons:null,
    applicationByID:null
    
  };
  export const getRegisterApplication = createAsyncThunk(
    "admin/registerApplication",
    async ({ pageNumber, pageSize }:any) => {
      const payerID = process.env.REACT_APP_PAYER_ID;
      const environment:any = process.env.REACT_APP_NODE_ENV==="staging" ? "Sandbox" : "Production"
      if (!payerID) {
        throw new Error("payerID is not defined in the environment variables");
      }
      try {
        const response = await ApplicationApi.getregisterdApps(payerID,environment, pageNumber, pageSize);   
        return response;
      } catch (error) {
        return error;
      }
    }
  );
  export const getApplicationByID = createAsyncThunk(
    "admin/getApplicationByID",
    async (id:number) => {
      try {
        const response = await ApplicationApi.getApplictionById(id);   
        return response;
      } catch (error) {
        return error;
      }
    }
  );
  export const updateApplicationStatus = createAsyncThunk(
    "admin/updateApplicationStatus",
    async (data:object) => {
      try {
        const response = await ApplicationApi.updateApplicationStatus(data);   
        return response;
      } catch (error) {
        return error;
      }
    }
  );
  const RegisterAppSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      RESET_STATE: () => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(getRegisterApplication.fulfilled, (state, action) => {
          state.isLoading = false;
          state.registerdApplicaitons = action.payload;
        })
        .addCase(getApplicationByID.fulfilled,(state,action)=>{
          state.isLoading=false;
          state.applicationByID=action.payload
        })
        .addMatcher(isPending, (state) => {
          state.isLoading = true;
          state.error = null;
        })
        .addMatcher(isRejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message ?? null;
        });
    },
  });
  export default RegisterAppSlice.reducer;
  