import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { dbInstance } from "../../api/axios";
import { formatToMMDDYYYY } from "../../utils/Utils";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ApplicationApi } from "../../service/Api/ApplicationsApi";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
}
const PatientSearch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [localPageSize, setLocalPageSize] = useState(10);
  const [isCleardData, setIsCleardData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isPageSizeChange, setIsPageSizeChange] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const[isClear,setIsClear]=useState(false)
  const navigate = useNavigate();
  const [searchCriteria, setSearchCriteria] = useState({
    MemberID: "",
    FirstName: "",
    LastName: "",
    GivenName: "",
    UserName: "",
    Email: "",
    DOB: "",
    RowStatus: "",
    BB_IsActive: "",
    BB_MemberID: "",
  });
  const [serchData, setSearchData] = useState({
    firstName: "",
    lastName: "",
    memberID: "",
    dob: "",
    email: "",
  });
  const [tempDate, setTempDate] = useState(null);
  const openNewTabWithUrl = (SSOGuid) => {
    const impersonateURL = process.env.REACT_APP_Impersonate_URL;
    const url = `${impersonateURL}${SSOGuid}`;
    window.open(url, "_blank");
  };
  const fetchIdRef = React.useRef(0);
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "DOB",
        accessor: "dob",
        Cell: ({ value }) => (value ? formatToMMDDYYYY(value) : "-"),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Member ID",
        accessor: "memberID",
        Cell: ({ value }) => (value ? value : "-"),
      },
      {
        Header: "Enable Blue Button",
        accessor: "bB_IsActive",
      },
      {
        Header: "View Profile",
        Cell: ({ row }) => (
          <i
            className="fh_open_in_new fs-4 primary cursor-pointer react-tooltip"
            data-tooltip-id="viewPatient"
            data-tooltip-content="View Patient Profile"
            data-tooltip-place="top"
            onClick={() => openNewTabWithUrl(row.original.ssoGuid)}
          ></i>
        ),
        disableSortBy: true,
      },
    ],
    []
  );
  const fetchPaginatedData = async () => {
    try {
      let accessToken = "";
      const PortalToken = localStorage.getItem("PortalAdmin-AccessToken");
      if (PortalToken) {
        accessToken = localStorage.getItem("PortalAdmin-AccessToken");
      } else {
        accessToken = await getAccessToken();
      }
      // Extracting sort parameters
      let sortParam = sortBy.length > 0 ? sortBy[0].id : null; // Column name for sorting
      let sortDirection = sortBy.length > 0 && sortBy[0].desc ? "DESC" : "ASC"; // Sort direction

      let filteredCriteria = {};

      let queryParams = new URLSearchParams({
        page: pageIndex + 1,

        ...filteredCriteria,
      });

      // Adding sort parameters if they are available
      if (sortParam) {
        queryParams.append("sort", sortParam);
        queryParams.append("sortDirection", sortDirection);
      }
      const response = await ApplicationApi.getPayerMemeberList();
      return response;
    } catch (error) {
      console.error("Error fetching patient data:", error);
      return { data: [], pageInfo: { totalCount: 0 } };
    }
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, sortBy: [] },
      manualPagination: true,
      manualSortBy: true,
      pageCount: pageCount,
    },
    useSortBy,
    usePagination
  );
  const fetchData = useCallback(
    async () => {
      if (loading) return; // Check if already fetching data
      setLoading(true);
      const fetchId = ++fetchIdRef.current;

      try {
        try {
        } catch (error) {
          if (error.response && error.response.status === 404) {
            navigate("/login");
          } else {
            console.error("Failed to fetch data:", error);
          }
        }
        const responseData = await fetchPaginatedData();
        if (fetchId === fetchIdRef.current) {
          setData(responseData?.data);
          setPageCount(responseData.totalPages);
          await setCurrentPage(responseData?.pageNumber);
         
          setTotalRecords(responseData.totalCount);
          setHasNextPage(responseData.hasNextPage);
          setHasPreviousPage(responseData.hasPreviousPage);
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );
  useEffect(() => {
    // Check if searchCriteria is reset to initial state
    const isCriteriaCleared = Object.values(searchCriteria).every(
      (value) => value === ""
    );
    if (isCriteriaCleared) {
      fetchData({
        pageSize: localPageSize,
        pageIndex: currentPage,
        sortBy,
        criteria: {},
      });
    }
  }, []);
  const handleClear = async () => {
    setSearchData(
      {
        memberID: "",
        firstName: "",
        lastName: "",
        email: "",
        dob: "",
      },
      setTempDate(""),
      

      await fetchData({
        pageSize: localPageSize,
        pageIndex: 0,
        sortBy,
        criteria: {},
      })
    );
    setIsClear(true)
    setLocalPageSize(10);
  };

  const handleSearchSubmit = async () => {
    setLoading(true);
    try {
      const response = await ApplicationApi.getPayerMemeberList(
        serchData.memberID,
        serchData.firstName,
        serchData.lastName,
        serchData.dob,
        serchData.email
      );
      if (response && response.data) {
        const fetchedData = response.data;
        setData(fetchedData);
        setTotalRecords(response.totalCount); // Update total records count
        setPageCount(response.totalPages); // Update page count
        setHasNextPage(response.hasNextPage);
        setHasPreviousPage(response.hasPreviousPage);
      }
     
    } catch (error) {
      console.error("Error fetching member list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setTempDate(date);

    const formattedDate = formatDateForQuery(date);
    setSearchData({
      ...serchData,
      dob: formattedDate,
    });
  };

  const formatDateForQuery = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      // return `${year}-${month}-${day}`;
      return `${month}-${day}-${year}`;
    }
    return "";
  };

  const handleChangePageSize = async (newPageSize) => {
    // Update the page size immediately without waiting for state change
    setIsPageSizeChange(true);
    setLocalPageSize(newPageSize);
  
    // Calculate new total pages based on the new page size
    const newTotalPages = Math.ceil(totalRecords / newPageSize);
    let newCurrentPage =
      currentPage >= newTotalPages ? newTotalPages - 1 : currentPage;
  
    // Make sure to reset to the first page when changing the page size
    if (isPageSizeChange) {
      newCurrentPage = 1; // Reset to the first page when changing page size
    }
  
    // Update the currentPage state
    setCurrentPage(newCurrentPage);
  
    // Fetch new data with the correct page size and page number
    try {
      const response = await ApplicationApi.getPayerMemeberList(
        serchData.memberID,
        serchData.firstName,
        serchData.lastName,
        serchData.dob,
        serchData.email,
        newCurrentPage,  // Use the new current page value
        newPageSize
      );
      if (response && response.data) {
        setData(response.data); // Update table data
        setTotalRecords(response.totalCount); // Update total records count
        setPageCount(response.totalPages); // Update page count
        setCurrentPage(response.pageNumber);
        setHasNextPage(response.hasNextPage);
        setHasPreviousPage(response.hasPreviousPage);
      }
    } catch (error) {
      console.error("Error fetching paginated data:", error);
    }
  };

  const handleNext = async () => {
    if (hasNextPage) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);  // Update the current page state
      fetchPageData(nextPage);   // Pass the correct next page number to the API
    }
  };
  
  const handlePrevious = async () => {
    setCurrentPage(currentPage - 1);
    fetchPageData(currentPage - 1); 
  };
  const handleGoToFirstPage = async () => {
    setCurrentPage(1);
    fetchPageData(1); 
  };
  const handleGoToLastPage = async () => {
    setCurrentPage(pageCount); // Set currentPage to pageCount
    fetchPageData(pageCount); // Fetch data for the last page
  };

  const fetchPageData = async (page) => {
    try {
      const response = await ApplicationApi.getPayerMemeberList(
        serchData.memberID,
        serchData.firstName,
        serchData.lastName,
        serchData.dob,
        serchData.email,
        page,
        localPageSize
      );
      if (response && response.data) {
        setData(response?.data);
        setHasNextPage(response.hasNextPage);
        setHasPreviousPage(response.hasPreviousPage);
        setCurrentPage(response.pageNumber)
      }
   
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <div className="main-content">
      <div className="content-header">
        <h1 className="page-header">Patient Search</h1>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="card-form form-content mt-0">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                <label htmlFor="FirstName" className="d-flex form-label">
                  First Name
                </label>
                <input
                  type="text"
                  name="FirstName"
                  className="form-text form-control"
                  value={serchData.firstName}
                  onChange={(e) =>
                    setSearchData({
                      ...serchData,
                      firstName: e.target.value,
                    })
                  }
                  placeholder="Search by First Name"
                />
              </div>
              <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                <label htmlFor="FirstName" className="d-flex form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  name="LastName"
                  className="form-text form-control"
                  value={serchData.lastName}
                  onChange={(e) =>
                    setSearchData({
                      ...serchData,
                      lastName: e.target.value,
                    })
                  }
                  placeholder="Search by Last Name"
                />
              </div>
              <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                <label htmlFor="DOB" className="d-flex form-label">
                  Date of Birth
                </label>
                <div className="form-field position-relative">
                  <DatePicker
                    id="DOB"
                    className={`form-control h-48`}
                    placeholderText="MM / DD / YYYY"
                    selected={tempDate}
                    onChange={handleDateChange}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                <label htmlFor="Email" className="d-flex form-label">
                  Email
                </label>
                <input
                  type="text"
                  name="Email"
                  className="form-text form-control"
                  value={serchData.email}
                  onChange={(e) =>
                    setSearchData({
                      ...serchData,
                      email: e.target.value,
                    })
                  }
                  placeholder="Search by Email"
                />
              </div>
              <div className="col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4">
                <label htmlFor="MemberID" className="d-flex form-label">
                  Member ID
                </label>
                <input
                  type="text"
                  name="MemberID"
                  className="form-text form-control"
                  value={serchData.memberID}
                  onChange={(e) =>
                    setSearchData({
                      ...serchData,
                      memberID: e.target.value,
                    })
                  }
                  placeholder="Search by Member ID"
                />
              </div>
            </div>
            <div className="col-12 text-center">
              <button
                type="button"
                data-tooltip-id="Aboutme"
                data-tooltip-content="About me"
                data-tooltip-place="right"
                className="btn btn-primary h-48 me-3"
                onClick={handleSearchSubmit}
              >
                Search
              </button>
              <button
                type="button"
                className="btn btn-border-primary h-48"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
          {/* Table for displaying data */}
          <div className="card-content">
            <div className="content-subheader d-flex align-items-center">
              <h2 className="page-subheader me-3">Patient List </h2>
              <span className="total-record">
                [ Total Records: {totalRecords} ]
              </span>
            </div>
            <div className="table-responsive">
              <Tooltip anchorSelect=".react-tooltip" />
              <table {...getTableProps()} className="table caption-top">
                <thead>
                  {/* Render table headers */}
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th>
                          {column.render("Header")}
                          {/* Sort direction indicators */}
                          <span className="ms-2 position-relative top-2">
                         
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {/* Conditional rendering for loading state and no data */}
                  {loading ? (
                    <tr>
                      <td colSpan={columns.length} className="text-center my-4">
                        <div className="spinner-border" role="status"></div>
                      </td>
                    </tr>
                  ) : data.length === 0 ? (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination */}
          {data && data.length > 0 && (
            <div className="d-flex justify-content-center">
              <nav
                aria-label="Page navigation"
                className="d-flex align-items-center"
              >
                <span className="pagnination-info me-2">
                  Page{" "}
                  <strong>
                    {currentPage?currentPage:1} of {pageCount}
                  </strong>{" "}
                </span>
                <ul className="pagination d-flex justify-content-end mb-0">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handleGoToFirstPage}
                      disabled={!hasPreviousPage}
                    >
                      <i className="fh_double_arrow_left"></i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handlePrevious}  
                      disabled={!hasPreviousPage}
                    >
                      <i className="fh_arrow_left_line"></i>
                    </button>
                  </li>
                  <li className="page-item"></li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handleNext} 
                        disabled={!hasNextPage}
                    >
                      <i className="fh_arrow_right_line"></i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={handleGoToLastPage} 
                      disabled={!hasNextPage}
                    >
                      <i className="fh_double_arrow_right"></i>
                    </button>
                  </li>
                </ul>
              </nav>
              <div>
                <select
                  className="form-select form-control cursor-pointer custom-table-dropdown"
                  value={localPageSize}
                  onChange={(e) => handleChangePageSize(Number(e.target.value))}
                >
                  {[10, 20, 30].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PatientSearch;
