import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getEnvVariable } from "../environments/env";
export class Http {
  private static axios = axios.create({
    baseURL: getEnvVariable().base_url,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static async get(url: string, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken')
      const headers = token ? { authorization: `Bearer ${token}`,ClientId: process.env.REACT_APP_PAYER_ID,  } : {};
      const updatedConfig: AxiosRequestConfig = { ...config, headers };
      const response = await Http.axios.get(url, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }

  static async checkEmailExistence(url: string, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken')
      const headers = token ? { authorization: `Bearer ${token}`,ClientId: process.env.REACT_APP_PAYER_ID,  } : {};
      const updatedConfig: AxiosRequestConfig = { ...config, headers };
      const response = await Http.axios.get(url, updatedConfig);
      return response;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }

  static async post(url: string, data: any, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken');
      const headers = {
        authorization: token ? `Bearer ${token}` : '',
        ClientId: process.env.REACT_APP_PAYER_ID, // Add ClientId to headers
        ...(config?.headers || {}), // Merge additional headers if any
      };
      const updatedConfig: AxiosRequestConfig = { ...config, headers };
      const response: AxiosResponse = await Http.axios.post(url, data, updatedConfig);
      return response.data;
    } catch (e) {
      return Http.handleErrors(e);
    }
  }
  static async put(url: string, body?: object, config?: AxiosRequestConfig) {
    try {
      const token = localStorage.getItem('PortalAdmin-AccessToken');
      
      const headers = token
      ? {
          Authorization: `Bearer ${token}`,
          ClientId: process.env.REACT_APP_PAYER_ID,
          'Content-Type': 'application/json', // Ensure content type is set
        }
      : {};
      const updatedConfig: AxiosRequestConfig = {
        ...config,
        headers: headers
      };
  
      const response = await Http.axios.put(url, body, updatedConfig);
      return response;
    } catch (e) {
      Http.handleErrors(e);
      return Promise.reject(e);
    }
  }
  
  static async postWithJson(url: string, body?: object, config?: AxiosRequestConfig) {
    try {
      const token = await localStorage.getItem("userToken");
      const headers: AxiosRequestConfig["headers"] = token ? { authorization: `Bearer ${token}` } : {};
      const updatedConfig: any = { ...config, headers: headers };
      const response = await Http.axios.post(
        url,
        body,
        updatedConfig,
      );
      if (response) {
        return response;
      }
    } catch (e) {
      Http.handleErrors(e);
      return Promise.reject(e);
    }
  }

  private static handleErrors(error: any) {
    if (error.response) {
      const errorMessage =
        error.response||
        "Something Went Wrong. Please Try Again";
       alert(errorMessage)
    } else {
     alert("Something went wrong. Please try again.");
    }
  }
}