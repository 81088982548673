const Footer = () => {
  return (
      <footer className='sticky-footer'>
        <div className='container mb-4'>
          <div className='copyright text-center my-auto'>
            <span>Copyright © AaNeel LLC {new Date().getFullYear()}</span>
          </div>
        </div>
      </footer>

  );
};
export default Footer;