import { WebStorageStateStore } from "oidc-client";

const oidcConfig = {
  authority: process.env.REACT_APP_MEMBER_APP_OIDC_AUTHORIRTY,
  client_id: process.env.REACT_APP_MEMBER_APP_OIDC_CLIENTID,
  client_secret: process.env.REACT_APP_MEMBER_APP_OIDC_CLIENTSECRET,
  redirect_uri: process.env.REACT_APP_MEMBER_APP_OIDC_REDIRECTURI,
  response_type: "code",
  scope: process.env.REACT_APP_MEMBER_APP_OIDC_SCOPE,
  // post_logout_redirect_uri:
  //   process.env.REACT_APP_MEMBER_APP_OIDC_POSTLOGOUTREDIRECTURI,
  automaticSilentRenew: true, // Enable automatic silent renewal
  silent_redirect_uri: `${window.location.origin}/auth-callback-silent`,
  monitorSession: true,
  checkSessionInterval: 2000,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  refresh_token_expiration: 1,
};
export default oidcConfig;